@use 'sass:math';
@use './media-queries' as mediaQueries;

@mixin actions-container() {
  background: var(--ds-neutral-50);
}

$pageTopSpacing: var(--ds-spacing);
$pageBottomSpacing: calc(2 * var(--ds-spacing));
$pageSideSpacing: calc(2 * var(--ds-spacing));
$pageSideSpacingSM: calc(1.5 * var(--ds-spacing));
$pageSideSpacingXS: var(--ds-spacing);

@mixin page-spacing($isPadding: true, $onlyHorizontal: false) {
  $type: 'margin';
  @if $isPadding {
    $type: 'padding';
  }

  @if $onlyHorizontal == false {
    #{$type}-top: $pageTopSpacing;
    #{$type}-bottom: $pageBottomSpacing;
  }

  #{$type}-left: $pageSideSpacing;
  #{$type}-right: $pageSideSpacing;

  @include mediaQueries.media-sm {
    #{$type}-left: $pageSideSpacingSM;
    #{$type}-right: $pageSideSpacingSM;
  }

  @include mediaQueries.media-xs {
    #{$type}-left: $pageSideSpacingXS;
    #{$type}-right: $pageSideSpacingXS;
  }
}

@mixin aspect-ratio($width, $height, $contentSelector: '.content') {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
  > #{$contentSelector} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  @include page-spacing();
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing))
    calc(2 * var(--ds-spacing));

  @include mediaQueries.media-sm {
    &.no-sidebar {
      padding-left: calc(2 * var(--ds-spacing)); // same as right
    }
    padding-right: calc(2 * var(--ds-spacing));
  }

  @media print {
    display: unset;
  }
}
